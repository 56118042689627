<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="mb-3 justify-content-center">
        <CCol md="7">
          <CCardGroup>
            <CCard
              style="background-color:#1361a0;"
              text-color="white"
              class="text-center py-5"
              body-wrapper
            >
              <CCardBody>
                <img src="/img/logo.png" />
              </CCardBody>
            </CCard>
            <CCard>
              <CCardBody>
                <CForm>
                  <h1>Bảo hành KLC</h1>
                  <p class="text-muted">
                    Nhập thông tin Số điện thoại hoặc Mã đơn hàng để xem thông
                    tin bảo hành
                  </p>
                  <CInput
                    placeholder="Số điện thoại"
                    type="text"
                    v-model="mobile"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-mobile" />
                    </template>
                  </CInput>
                  <CInput
                    placeholder="Mã đơn hàng"
                    type="text"
                    v-model="orderNumber"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-barcode" />
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CSpinner
                        v-if="loading"
                        color="info"
                        size="sm"
                        class="ml-2"
                      />
                      <CButton
                        v-else
                        style="background-color:#1361a0;"
                        class="px-4 text-light"
                        @click="searchWarranty"
                      >
                        Tìm kiếm
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <CRow v-if="warrantyInfo" class="mb-3 justify-content-center">
        <CCol md="7">
          <CCard
            border-color="success"
            class="border-top-3 border-top-success mb-0"
          >
            <CCardHeader>
              <h5>
                <CIcon size="lg" name="cil-calendar-check" />
                Thông tin bảo hành
              </h5>
            </CCardHeader>
            <CCardBody class="p-2">
              <div class="cust-info mb-2">
                <div class="row">
                  <div class="col-5 text-right">
                    Họ và Tên:
                  </div>
                  <div class="col-7 font-weight-bold">
                    {{ warrantyInfo.name }}
                  </div>
                </div>
                <div v-if="warrantyInfo.mobile" class="row">
                  <div class="col-5 text-right">
                    Điện thoại:
                  </div>
                  <div class="col-7 font-weight-bold">
                    **** *** {{ warrantyInfo.mobile }}
                  </div>
                </div>
                <div v-if="warrantyInfo.address" class="row">
                  <div class="col-5 text-right">
                    Địa chỉ:
                  </div>
                  <div class="col-7 font-weight-bold">
                    {{ warrantyInfo.address }}
                  </div>
                </div>
              </div>

              <div
                v-for="(item, i) in products"
                :key="`product-${i}`"
                class="card mb-2"
              >
                <div class="card-body">
                  <div class="h5 fw-bold">
                    <CIcon name="cilBookmark" />
                    Sản phẩm: {{ item.productName }}
                  </div>
                  <div class="mb-3">
                    <CIcon name="cilStar" />
                    Cửa hàng: {{ item.agencyName }}
                  </div>
                  <div
                    v-for="(warranty, i) in item.warranties"
                    :key="`warranty-${i}`"
                    class="pb-3"
                  >
                    <div class="clearfix">
                      <div class="float-left">
                        <strong
                          >{{
                            calcUsedPercent(
                              warranty.startDate,
                              warranty.months
                            )
                          }}%</strong
                        >
                      </div>
                      <div class="float-right">
                        <small class="text-muted">
                          Từ
                          {{
                            $moment
                              .utc(warranty.startDate)
                              .local()
                              .format("DD/MM/YYYY")
                          }}
                          - Đến
                          {{
                            $moment(warranty.startDate)
                              .add(warranty.months, "M")
                              .local()
                              .format("DD/MM/YYYY")
                          }}
                        </small>
                      </div>
                    </div>
                    <CProgress
                      class="progress-xs"
                      :value="
                        calcUsedPercent(warranty.startDate, warranty.months)
                      "
                      :color="
                        color(
                          calcUsedPercent(warranty.startDate, warranty.months)
                        )
                      "
                    />
                    <div>
                      {{ $const.WARRANTY_TYPES_TEXT[warranty.warrantyType] }}
                      <strong>{{ monthsToYears(warranty.months) }}.</strong>
                      <template
                        v-if="
                          monthsToYears(
                            calcRemain(warranty.startDate, warranty.months)
                          )
                        "
                      >
                        Còn
                        <strong
                          >{{
                            monthsToYears(
                              calcRemain(warranty.startDate, warranty.months)
                            )
                          }}.</strong
                        >
                      </template>
                      <template v-else>
                        Hết bảo hành.
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <CDataTable
                class="mb-0 table-outline table-sm"
                hover
                :items="products"
                :fields="tableFields"
                head-color="light"
                no-sorting
              >
                <td slot="product" slot-scope="{ item }">
                  <div class="">{{ item.productName }}</div>
                  <div class="small text-muted">
                    {{ item.agencyName }}
                  </div>
                </td>
                <td slot="warranty" slot-scope="{ item }">
                  <div
                    v-for="(warranty, i) in item.warranties"
                    :key="`warranty-${i}`"
                    class="pb-3"
                  >
                    <div>
                      {{ $const.WARRANTY_TYPES_TEXT[warranty.warrantyType] }}
                      <strong>{{ monthsToYears(warranty.months) }}.</strong>
                      <template
                        v-if="
                          monthsToYears(
                            calcRemain(warranty.startDate, warranty.months)
                          )
                        "
                      >
                        Còn
                        <strong
                          >{{
                            monthsToYears(
                              calcRemain(warranty.startDate, warranty.months)
                            )
                          }}.</strong
                        >
                      </template>
                      <template v-else>
                        Hết bảo hành.
                      </template>
                    </div>
                    <div class="clearfix">
                      <div class="float-left">
                        <strong
                          >{{
                            calcUsedPercent(
                              warranty.startDate,
                              warranty.months
                            )
                          }}%</strong
                        >
                      </div>
                      <div class="float-right">
                        <small class="text-muted">
                          Từ
                          {{
                            $moment
                              .utc(warranty.startDate)
                              .local()
                              .format("DD/MM/YYYY")
                          }}
                          - Đến
                          {{
                            $moment(warranty.startDate)
                              .add(warranty.months, "M")
                              .local()
                              .format("DD/MM/YYYY")
                          }}
                        </small>
                      </div>
                    </div>
                    <CProgress
                      class="progress-xs"
                      :value="
                        calcUsedPercent(warranty.startDate, warranty.months)
                      "
                      :color="
                        color(
                          calcUsedPercent(warranty.startDate, warranty.months)
                        )
                      "
                    />
                  </div>
                </td>
              </CDataTable> -->
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <div class="row justify-content-center mb-3">
        <!-- <div class="col-auto">
          Hotline bảo hành:
          <span class="font-weight-bold">0382 53 53 53</span>.
        </div> -->
        <div class="col-auto">
          Hotline Chăm Sóc Khách Hàng:
          <span class="font-weight-bold">1800 0076</span>.
        </div>
      </div>
    </CContainer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: null,
      orderNumber: null,
      loading: false,
      warrantyInfo: null,
      tableFields: [
        { key: "product", label: "Sản phẩm" },
        { key: "warranty", label: "Bảo hành" },
      ],
    };
  },
  async mounted() {
    let orderNumber = this.$route.query["don-hang"];
    let mobile = this.$route.query["dien-thoai"];
    if (orderNumber) {
      this.orderNumber = orderNumber;
    }
    if (mobile) {
      this.mobile = mobile;
    }
  },
  computed: {
    products() {
      let warrantyInfo = this.warrantyInfo;
      let results = [];

      if (warrantyInfo && warrantyInfo.orders) {
        warrantyInfo.orders.forEach((o) => {
          if (o.products) {
            o.products.forEach((p) => {
              if (p.warranties && p.warranties.length) {
                results.push({
                  agencyName: o.agencyName,
                  productName: p.productName,
                  warranties: p.warranties.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex(
                        (t) =>
                          t.warrantyType === value.warrantyType &&
                          t.months === value.months &&
                          t.startDate === value.startDate
                      )
                  ),
                });
              }
            });
          }
        });
      }
      return results;
    },
  },
  methods: {
    async searchWarranty() {
      if (!this.orderNumber && !this.mobile) {
        alert("Vui lòng nhập thông tin để tìm kiếm!");
        return;
      }
      this.loading = true;
      try {
        let params = {};
        if (this.orderNumber) {
          params["orderNumber"] = this.orderNumber;
        }
        if (this.mobile) {
          params["mobile"] = this.mobile;
        }
        let resp = await this.$http.get(`/Warranty`, {
          params,
        });
        if (resp && resp.status == 200) {
          this.warrantyInfo = resp.data;
        } else {
          this.warrantyInfo = null;
        }
      } catch (error) {
        this.warrantyInfo = null;
      }
      if (!this.warrantyInfo) {
        alert("Không tìm thấy thông tin bảo hành!");
      }
      this.loading = false;
    },
    calcRemain(startDate, months) {
      let endDate = this.$moment(startDate).add(months, "M");
      let now = this.$moment(new Date()).utc();
      let remain = Math.ceil(this.$moment(endDate).diff(now, "months", true));
      return remain && remain > 0 ? remain : 0;
    },
    calcUsedPercent(startDate, months) {
      let now = this.$moment(new Date()).utc();
      let usedMonth = this.$moment(now).diff(startDate, "months", true);
      let percent = Math.ceil((usedMonth / months) * 100);
      return percent ? (percent > 100 ? 100 : percent) : 0;
    },
    color(value) {
      let $color;
      if (value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75) {
        $color = "danger";
      }
      return $color;
    },

    monthsToYears(months) {
      let result = [];
      let year = Math.floor(months / 12);
      let month = months % 12;
      year && result.push(`${year} năm`);
      month && result.push(`${month} tháng`);
      return result.join(" ");
    },
  },
};
</script>
